import * as React from 'react';
import api from '../../services/api';
import apiStringServer from '../../services/apiStringServer';

import {
  Box, Card, CardContent, IconButton, Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Button, Typography, Avatar, Grid, FormControl, InputLabel, Select, Stack, Autocomplete, TextField, FormControlLabel, 
  Switch, Backdrop, CircularProgress, Menu, MenuItem
} from '@mui/material';

import { Edit, Delete, Visibility, ImportExport, PictureAsPdf, Grid4x4, Article } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

import { DataGrid, ptBR, GridToolbar } from '@mui/x-data-grid';
import styles from '../styles/styles';
import userEvent from '@testing-library/user-event';

export default function GridView({ profile, props }) {

  React.useEffect(() => {

    getGroupList();
    getSessionList();

  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [product_id, setProductId] = React.useState('');
  const [objectType, setObjectType] = React.useState('select');
  const [situation, setSituation] = React.useState([{ situation: 'ativo', label: '🟢 Ativo' }]);

  const [code, setCode] = React.useState('');
  const [name, setName] = React.useState('');
  const [expenseNature, setExpenseNature] = React.useState('');

  const [advancedSearch, setAdvancedSearch] = React.useState(false);

  const [group, setGroup] = React.useState('');
  const [classification, setClassification] = React.useState('');
  const [pdm, setPdm] = React.useState('');
  const [material, setMaterial] = React.useState('');

  const [groupList, setGroupList] = React.useState([]);
  const [classList, setClassList] = React.useState([]);
  const [pdmList, setPdmList] = React.useState([]);
  const [materialList, setMaterialList] = React.useState([]);

  const [session, setSession] = React.useState('');
  const [division, setDivision] = React.useState('');
  const [serviceGroup, setServiceGroup] = React.useState('');
  const [serviceClass, setServiceClass] = React.useState('');
  const [serviceSubclass, setServiceSubclass] = React.useState('');
  const [service, setService] = React.useState('');

  const [sessionList, setSessionList] = React.useState([]);
  const [divisionList, setDivisionList] = React.useState([]);
  const [serviceGroupList, setServiceGroupList] = React.useState([]);
  const [serviceClassList, setServiceClassList] = React.useState([]);
  const [serviceSubclassList, setServiceSubclassList] = React.useState([]);
  const [serviceList, setServiceList] = React.useState([]);

  const [supplyLine, setSupplyLine] = React.useState('');
  const [expenseProduct, setExpenseProduct] = React.useState('');
  const [productValue, setProductValue] = React.useState('');
  const [productDescription, setProductDescription] = React.useState('');

  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function getGroupList() {
    await setGroupList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/materiais/v1/grupos.json');

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setGroupList(data._embedded.grupos);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getClassList(group) {
    await setClassList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/materiais/v1/classes.json?codigo_grupo=' + group);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setClassList(data._embedded.classes);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getPdmList(classification) {
    await setPdmList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/materiais/v1/pdms.json?codigo_classe=' + classification);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setPdmList(data._embedded.pdms);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getMaterialList(pdm) {
    await setMaterialList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/materiais/v1/materiais.json?pdm=' + pdm); // + '&classe=' + classification + '&grupo=' + group

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setMaterialList(data._embedded.materiais);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getSessionList() {
    await setSessionList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/secoes.json');

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setSessionList(data._embedded.secoes);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getDivisionList(session) {
    await setDivisionList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/divisoes.json?codigo_secao=' + session);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setDivisionList(data._embedded.divisoes);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getServiceGroupList(division) {
    await setServiceGroupList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/grupos.json?codigo_divisao=' + division);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceGroupList(data._embedded.grupos);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getServiceClassList(group) {
    await setServiceClassList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/classes.json?codigo_grupo=' + group);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceClassList(data._embedded.classes);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getServiceSubclassList(classification) {
    await setServiceSubclassList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/subclasses.json?codigo_classe=' + classification);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceSubclassList(data._embedded.subclasses);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getServiceList(subclass) {
    await setServiceList([]);

    if (subclass !== null) {
      try {
        const response = await fetch('https://compras.dados.gov.br/servicos/v1/servicos.json?subclasse=' + subclass + '&classe=' + 
          serviceClass + '&grupo=' + serviceGroup + '&divisao=' + division + '&secao=' + session);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setServiceList(data._embedded.servicos);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await fetch('https://compras.dados.gov.br/servicos/v1/servicos.json?classe=' + 
          serviceClass + '&grupo=' + serviceGroup + '&divisao=' + division + '&secao=' + session);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setServiceList(data._embedded.servicos);
          }
        }
      } catch (error) {
        try {
          const response = await fetch('https://compras.dados.gov.br/servicos/v1/servicos.json?grupo=' + serviceGroup + '&divisao=' + 
          division + '&secao=' + session);
    
          const data = await response.json();
    
          if (data) {
            if (JSON.stringify(data).includes('descricao')) {
              setServiceList(data._embedded.servicos);
            }
          }
        } catch (error) {
          console.log('Erro ao procurar serviços: ' + error);
        }
      }
    }

  }

  function Actions({ props }) {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [status, setStatus] = React.useState('select');

    const [productArray, setProductArray] = React.useState([]);

    const [groupById, setGroupById] = React.useState('');
    const [classById, setClassById] = React.useState('');
    const [pdmById, setPdmById] = React.useState('');
    const [materialById, setMaterialById] = React.useState('');

    const [sessionById, setSessionById] = React.useState('');
    const [divisionById, setDivisionById] = React.useState('');
    const [serviceGroupById, setServiceGroupById] = React.useState('');
    const [serviceClassById, setServiceClassById] = React.useState('');
    const [serviceSubclassById, setServiceSubclassById] = React.useState('');
    const [serviceById, setServiceById] = React.useState('');

    async function getGroupById(groupValue) {
      await setGroupById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/materiais/doc/grupo/${groupValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setGroupById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getClassById(classValue) {
      await setClassById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/materiais/id/classe/${classValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setClassById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getPdmById(pdmValue) {
      await setPdmById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/materiais/id/pdm/${pdmValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setPdmById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getMaterialById(materialValue) {
      await setMaterialById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/materiais/id/material/${materialValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setMaterialById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getSessionById(sessionValue) {
      await setSessionById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/servicos/id/secao/${sessionValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setSessionById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getDivisionById(divisionValue) {
      await setDivisionById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/servicos/id/divisao/${divisionValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setDivisionById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getServiceGroupById(serviceGroupValue) {
      await setServiceGroupById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/servicos/id/grupo/${serviceGroupValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setServiceGroupById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getServiceClassById(serviceClassValue) {
      await setServiceClassById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/servicos/id/classe/${serviceClassValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setServiceClassById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getServiceSubclassById(serviceSubclassValue) {
      await setServiceSubclassById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/servicos/id/subclasse/${serviceSubclassValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setServiceSubclassById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function getServiceById(serviceValue) {
      await setServiceById('');
  
      try {
        const response = await fetch(`https://compras.dados.gov.br/servicos/id/servico/${serviceValue}.json`);
  
        const data = await response.json();
  
        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setServiceById(JSON.stringify(data.descricao).replace(/"/g, ''));
          }
        }
      } catch (error) {
        //alert(error);
      }
  
    }
  
    async function handleSee() {
  
      for (let i = 0; i < productArray.length; i++) {
        productArray.pop();
      }
  
      try {
        let resp = await api.get('/product', {
          params: {
            id: product_id,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
              await setProductArray([]);
              productArray.push(resp.data);
              await setProductArray(productArray);
              await getGroupById(productArray[0].product_group);
              await getClassById(productArray[0].class);
              await getPdmById(productArray[0].pdm);
              await getMaterialById(productArray[0].material);
              await getSessionById(productArray[0].session);
              await getDivisionById(productArray[0].division);
              await getServiceGroupById(productArray[0].service_group);
              await getServiceClassById(productArray[0].service_class);
              await getServiceSubclassById(productArray[0].service_subclass);
              await getServiceById(productArray[0].service);
              setOpenDialog(true);
            }
          }
        }
      } catch (error) {
        alert(error);
      }
  
    }

    async function updateStatus() {

      try {

        let resp = await api.get('/updatestatus', {
          params: {
            product_id: product_id,
            situation: status,
            user_id: profile.id
          }
        });

        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('updated')) {
              setOpenEditDialog(false);
              getProducts2();
            }
          }
        }

      } catch (error) {
        setOpenEditDialog(true);
        alert(error);
      }

    }
  
    return(
      <Box>
        <IconButton
          color="inherit"
          size="small"
          aria-label="see"
          onClick={() => { handleSee(); }}
        >
          <Visibility fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="edit"
          onClick={() => { setOpenEditDialog(true); }}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
        >
          <Delete fontSize="small" />
        </IconButton>
  
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Produto</DialogTitle>
          <DialogContent>
            <DialogContentText color="#000">
              Detalhes do produto/serviço.
            </DialogContentText>
  
            {productArray.map((product) => 
              <Box>
                <Avatar
                  src={`${apiStringServer}/products_img/${product.photo_url}`}
                  sx={{ width: 200, height: 200, borderRadius: 0, borderWidth: 1, borderColor: '#000' }}
                />
                <Typography color="textSecondary" variant="body2">
                  Código: {product.code}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Nome: {product.name}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Descrição: {product.description}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Valor: R$ {product.product_value}
                </Typography>
                <Typography color="textPrimary" variant="h5">
                  Outras informações
                </Typography>
                {groupById !== '' ? (
                  <Typography color="textSecondary" variant="body2">
                    Grupo - Classe: {groupById} - {classById}
                  </Typography>
                ) : null}
                {pdmById ? (
                  <Typography color="textSecondary" variant="body2">
                    PDM: {pdmById}
                  </Typography>
                ) : null}
                {materialById ? (
                  <Typography color="textSecondary" variant="body2">
                    Material: {materialById}
                  </Typography>
                ) : null}
                {sessionById !== '' ? (
                  <Typography color="textSecondary" variant="body2">
                    Sessão - Divisão: {sessionById} - {divisionById}
                  </Typography>
                ) : null}
                {serviceGroupById ? (
                  <Typography color="textSecondary" variant="body2">
                    Grupo: {serviceGroupById}
                  </Typography>
                ) : null}
                {serviceClassById ? (
                  <Typography color="textSecondary" variant="body2">
                    Classe: {serviceClassById}
                  </Typography>
                ) : null}
                {serviceSubclassById ? (
                  <Typography color="textSecondary" variant="body2">
                    Subclasse: {serviceSubclassById}
                  </Typography>
                ) : null}
                {serviceById ? (
                  <Typography color="textSecondary" variant="body2">
                    Serviço: {serviceById}
                  </Typography>
                ) : null}
                <Typography color="textSecondary" variant="body2">
                  Linha de fornecimento: {product.supply_line}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Natureza da despesa: {product.expense_nature}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Elemento/Item de despesa: {product.expense_product}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  ---
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Usuário responsável: {product.user_name} - {product.type === 3 ? 'Administrador' : product.type === 1 ? 'Órgão' : 'Servidor'}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  E-mail: {product.email}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Secretaria: {product.tree_name}
                </Typography>
              </Box>
            )}
  
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Situação da requisição</DialogTitle>
          <DialogContent>
            <DialogContentText color="#000">
              Altere o status após a análise.
            </DialogContentText>

            <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
              <InputLabel htmlFor="outlined-status-native-simple">
                Status *
              </InputLabel>
              <Select
                native
                required
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                inputProps={{
                  name: 'status',
                  id: 'outlined-status-native-simple',
                }}
              >
                <option value="select">Selecione o status do item</option>
                <option value="ativo">🟢 Ativo</option>
                <option value="suspenso">🟡 Suspenso para compra</option>
                <option value="inativo">🔴 Inativo</option>
              </Select>
            </FormControl>
  
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditDialog(false)}>
              Fechar
            </Button>
          </DialogActions>
          <DialogActions>
            <Button onClick={() => updateStatus()}>
              Alterar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'code',
      headerName: 'Código',
      width: 150,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Código (NCM) '}
          <span role="img" aria-label="enjoy">
            📇
          </span>
        </strong>
      ),
    },
    {
      field: 'name',
      headerName: 'Nome',
      width: 170,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Nome '}
          <span role="img" aria-label="enjoy">
            🔤
          </span>
        </strong>
      ),
    },
    {
      field: 'secretary',
      headerName: 'Secretaria',
      width: 250,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Secretaria '}
          <span role="img" aria-label="enjoy">
            🏬
          </span>
        </strong>
      ),
    },
    {
      field: 'expense_nature',
      headerName: 'Natureza da despesa',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Natureza da despesa '}
          <span role="img" aria-label="enjoy">
            💲
          </span>
        </strong>
      ),
    },
    {
      field: 'group',
      headerName: 'Grupo',
      width: 250,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Grupo '}
          <span role="img" aria-label="enjoy">
            👥
          </span>
        </strong>
      ),
    },
    {
      field: 'class',
      headerName: 'Classe',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Classe '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'pdm',
      headerName: 'PDM',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'PDM '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'material',
      headerName: 'Material',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Material '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'session',
      headerName: 'Sessão',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Sessão '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'division',
      headerName: 'Divisão',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Divisão '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'service_group',
      headerName: 'Grupo de Serviço',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Grupo de Serviço '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'service_class',
      headerName: 'Classe de Serviço',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Classe de Serviço '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'service_subclass',
      headerName: 'Subclasse de Serviço',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Subclasse de Serviço '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'service',
      headerName: 'Serviço',
      width: 200,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Serviço '}
          <span role="img" aria-label="enjoy">
            ↕️
          </span>
        </strong>
      ),
    },
    {
      field: 'supply_line',
      headerName: 'Linha de fornecimento',
      width: 230,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Linha de fornecimento '}
          <span role="img" aria-label="enjoy">
            🚚
          </span>
        </strong>
      ),
    },
    {
      field: 'expense_product',
      headerName: 'Elelemento-produto de despesa',
      width: 250,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Elelemento-produto de despesa '}
          <span role="img" aria-label="enjoy">
            📊
          </span>
        </strong>
      ),
    },
    {
      field: 'product_value',
      headerName: 'Valor',
      width: 130,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Valor R$ '}
          <span role="img" aria-label="enjoy">
            💵
          </span>
        </strong>
      ),
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 300,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Descrição '}
          <span role="img" aria-label="enjoy">
            📝
          </span>
        </strong>
      ),
    },
    {
      field: 'situation',
      headerName: 'Situação',
      width: 230,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Situação '}
          <span role="img" aria-label="enjoy">
            ✔️
          </span>
        </strong>
      ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: () => (
        <Actions />
      ),
      sortable: false,
      width: 100,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  let rows = [];

  let rowsArray = [];

  const [products, setProducts] = React.useState([]);
  const [page_rows, setPageRows] = React.useState([]);

  async function getGroupById(groupValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/materiais/doc/grupo/${groupValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getClassById(classValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/materiais/id/classe/${classValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getPdmById(pdmValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/materiais/id/pdm/${pdmValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getMaterialById(materialValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/materiais/id/material/${materialValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getSessionById(sessionValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/secao/${sessionValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getDivisionById(divisionValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/divisao/${divisionValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getServiceGroupById(serviceGroupValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/grupo/${serviceGroupValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getServiceClassById(serviceClassValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/classe/${serviceClassValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getServiceSubclassById(serviceSubclassValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/subclasse/${serviceSubclassValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  async function getServiceById(serviceValue) {

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/servico/${serviceValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          return JSON.stringify(data.descricao).replace(/"/g, '');
        }
      }
    } catch (error) {
      //alert(error);
    }

  }

  React.useEffect(() => {

    getProducts();

  }, []);

  async function getProducts() {

    rows = [];
    for (let i = 0; i < page_rows.length; i++) {
      page_rows.pop();
    }
    for (let i = 0; i < products.length; i++) {
      products.pop();
    }
    setBackdropOpen(true);

    if (profile.type === 4 || profile.type === 5) {
      try {
        let resp = await api.get('/secretaryproducts', {
          params: {
            secretary_id: profile.secretary_id,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
              await setProducts([]);
              products.push(resp.data);
              await setProducts(products);
  
              let product_group = [];
              let product_class = [];
              let product_pdm = [];
              let product_material = [];
              let service_session = [];
              let service_division = [];
              let service_group = [];
              let service_class = [];
              let service_subclass = [];
              let service = [];
  
              for (let i = 0; i < resp.data.length; i++) {
                let situation = '';
                if (resp.data[i].situation === 'ativo') {
                  situation = '🟢 Ativo';
                } else {
                  if (resp.data[i].situation === 'suspenso') {
                    situation = '🟡 Suspenso para compra';
                  } else {
                    situation = '🔴 Inativo';
                  }
                }

                let aux = {
                  id: resp.data[i].id,
                  code: resp.data[i].code,
                  name: resp.data[i].name,
                  secretary: resp.data[i].tree_name,
                  expense_nature: resp.data[i].expense_nature,
                  supply_line: resp.data[i].supply_line,
                  expense_product: resp.data[i].expense_product,
                  product_value: resp.data[i].product_value,
                  description: resp.data[i].description,
                  situation: situation,
                };

                rows.push(aux);
              }
  
              setPageRows(rows);

              for (let i = 0; i < resp.data.length; i++) {
                product_group.push(await getGroupById(resp.data[i].product_group));
                product_class.push(await getClassById(resp.data[i].class));
                product_pdm.push(await getPdmById(resp.data[i].pdm));
                product_material.push(await getMaterialById(resp.data[i].material));
                service_session.push(await getSessionById(resp.data[i].session));
                service_division.push(await getDivisionById(resp.data[i].division));
                service_group.push(await getServiceGroupById(resp.data[i].service_group));
                service_class.push(await getServiceClassById(resp.data[i].service_class));
                service_subclass.push(await getServiceSubclassById(resp.data[i].service_subclass));
                service.push(await getServiceById(resp.data[i].service));

                if (product_group[i] !== undefined || product_class[i] !== undefined) {
                  rows = [];
                  let aux = {
                    id: resp.data[i].id,
                    code: resp.data[i].code,
                    name: resp.data[i].name,
                    secretary: resp.data[i].tree_name,
                    expense_nature: resp.data[i].expense_nature,
                    group: product_group[i],
                    class: product_class[i],
                    pdm: product_pdm[i],
                    material: product_material[i],
                    session: service_session[i],
                    division: service_division[i],
                    service_group: service_group[i],
                    service_class: service_class[i],
                    service_subclass: service_subclass[i],
                    service: service[i],
                    supply_line: resp.data[i].supply_line,
                    expense_product: resp.data[i].expense_product,
                    product_value: resp.data[i].product_value,
                    description: resp.data[i].description,
                    situation: situation,
                  };
                  //rows = Object.assign([], rows);
                  rows.push(aux);
                  setBackdropOpen(false);
                }
              }

              setPageRows(rows);
  
            } else {
              setBackdropOpen(false);
              //alert(resp.data);
            }
          }
        }
        setBackdropOpen(false);
      } catch (error) {
        setBackdropOpen(false);
        alert(error);
      }
    } else {
      try {
        let resp = await api.get('/products', {
          params: {
            place: profile.place,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
              await setProducts([]);
              products.push(resp.data);
              await setProducts(products);
  
              let product_group = [];
              let product_class = [];
              let product_pdm = [];
              let product_material = [];
              let service_session = [];
              let service_division = [];
              let service_group = [];
              let service_class = [];
              let service_subclass = [];
              let service = [];
  
              for (let i = 0; i < resp.data.length; i++) {
                let situation = '';
                if (resp.data[i].situation === 'ativo') {
                  situation = '🟢 Ativo';
                } else {
                  if (resp.data[i].situation === 'suspenso') {
                    situation = '🟡 Suspenso para compra';
                  } else {
                    situation = '🔴 Inativo';
                  }
                }

                let aux = {
                  id: resp.data[i].id,
                  code: resp.data[i].code,
                  name: resp.data[i].name,
                  secretary: resp.data[i].tree_name,
                  expense_nature: resp.data[i].expense_nature,
                  supply_line: resp.data[i].supply_line,
                  expense_product: resp.data[i].expense_product,
                  product_value: resp.data[i].product_value,
                  description: resp.data[i].description,
                  situation: situation,
                };

                rows.push(aux);
              }
  
              setPageRows(rows);

              for (let i = 0; i < resp.data.length; i++) {
                product_group.push(await getGroupById(resp.data[i].product_group));
                product_class.push(await getClassById(resp.data[i].class));
                product_pdm.push(await getPdmById(resp.data[i].pdm));
                product_material.push(await getMaterialById(resp.data[i].material));
                service_session.push(await getSessionById(resp.data[i].session));
                service_division.push(await getDivisionById(resp.data[i].division));
                service_group.push(await getServiceGroupById(resp.data[i].service_group));
                service_class.push(await getServiceClassById(resp.data[i].service_class));
                service_subclass.push(await getServiceSubclassById(resp.data[i].service_subclass));
                service.push(await getServiceById(resp.data[i].service));

                if (product_group[i] !== undefined || product_class[i] !== undefined) {
                  rows = [];
                  let aux = {
                    id: resp.data[i].id,
                    code: resp.data[i].code,
                    name: resp.data[i].name,
                    secretary: resp.data[i].tree_name,
                    expense_nature: resp.data[i].expense_nature,
                    group: product_group[i],
                    class: product_class[i],
                    pdm: product_pdm[i],
                    material: product_material[i],
                    session: service_session[i],
                    division: service_division[i],
                    service_group: service_group[i],
                    service_class: service_class[i],
                    service_subclass: service_subclass[i],
                    service: service[i],
                    supply_line: resp.data[i].supply_line,
                    expense_product: resp.data[i].expense_product,
                    product_value: resp.data[i].product_value,
                    description: resp.data[i].description,
                    situation: situation,
                  };
                  //rows = Object.assign([], rows);
                  rows.push(aux);
                  setBackdropOpen(false);
                }
              }

              setPageRows(rows);
  
            } else {
              setBackdropOpen(false);
              //alert(resp.data);
            }
          }
        }
        setBackdropOpen(false);
      } catch (error) {
        setBackdropOpen(false);
        alert(error);
      }
    }

  }

  async function getProducts2() {

    setBackdropOpen(true);

    if (profile.type === 4 || profile.type === 5) {
      try {
        let resp = await api.get('/secretaryproducts', {
          params: {
            secretary_id: profile.secretary_id,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
              await setProducts([]);
              products.push(resp.data);
              await setProducts(products);
  
              let product_group = [];
              let product_class = [];
              let product_pdm = [];
              let product_material = [];
              let service_session = [];
              let service_division = [];
              let service_group = [];
              let service_class = [];
              let service_subclass = [];
              let service = [];
  
              for (let i = 0; i < resp.data.length; i++) {
                let situation = '';
                if (resp.data[i].situation === 'ativo') {
                  situation = '🟢 Ativo';
                } else {
                  if (resp.data[i].situation === 'suspenso') {
                    situation = '🟡 Suspenso para compra';
                  } else {
                    situation = '🔴 Inativo';
                  }
                }

                let aux = {
                  id: resp.data[i].id,
                  code: resp.data[i].code,
                  name: resp.data[i].name,
                  secretary: resp.data[i].tree_name,
                  expense_nature: resp.data[i].expense_nature,
                  supply_line: resp.data[i].supply_line,
                  expense_product: resp.data[i].expense_product,
                  product_value: resp.data[i].product_value,
                  description: resp.data[i].description,
                  situation: situation,
                };

                rows.push(aux);
              }
  
              setPageRows(rows);
              setBackdropOpen(false);

              for (let i = 0; i < resp.data.length; i++) {
                product_group.push(await getGroupById(resp.data[i].product_group));
                product_class.push(await getClassById(resp.data[i].class));
                product_pdm.push(await getPdmById(resp.data[i].pdm));
                product_material.push(await getMaterialById(resp.data[i].material));
                service_session.push(await getSessionById(resp.data[i].session));
                service_division.push(await getDivisionById(resp.data[i].division));
                service_group.push(await getServiceGroupById(resp.data[i].service_group));
                service_class.push(await getServiceClassById(resp.data[i].service_class));
                service_subclass.push(await getServiceSubclassById(resp.data[i].service_subclass));
                service.push(await getServiceById(resp.data[i].service));

                if (product_group[i] !== undefined || product_class[i] !== undefined) {
                  rows = [];
                  let aux = {
                    id: resp.data[i].id,
                    code: resp.data[i].code,
                    name: resp.data[i].name,
                    secretary: resp.data[i].tree_name,
                    expense_nature: resp.data[i].expense_nature,
                    group: product_group[i],
                    class: product_class[i],
                    pdm: product_pdm[i],
                    material: product_material[i],
                    session: service_session[i],
                    division: service_division[i],
                    service_group: service_group[i],
                    service_class: service_class[i],
                    service_subclass: service_subclass[i],
                    service: service[i],
                    supply_line: resp.data[i].supply_line,
                    expense_product: resp.data[i].expense_product,
                    product_value: resp.data[i].product_value,
                    description: resp.data[i].description,
                    situation: situation,
                  };
                  //rows = Object.assign([], rows);
                  rows.push(aux);
                }
              }

              setPageRows(rows);
  
            } else {
              setBackdropOpen(false);
              //alert(resp.data);
            }
          }
        }
        setBackdropOpen(false);
      } catch (error) {
        setBackdropOpen(false);
        alert(error);
      }
    } else {
      try {
        let resp = await api.get('/products', {
          params: {
            place: profile.place,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
              await setProducts([]);
              products.push(resp.data);
              await setProducts(products);
  
              let product_group = [];
              let product_class = [];
              let product_pdm = [];
              let product_material = [];
              let service_session = [];
              let service_division = [];
              let service_group = [];
              let service_class = [];
              let service_subclass = [];
              let service = [];
  
              for (let i = 0; i < resp.data.length; i++) {
                let situation = '';
                if (resp.data[i].situation === 'ativo') {
                  situation = '🟢 Ativo';
                } else {
                  if (resp.data[i].situation === 'suspenso') {
                    situation = '🟡 Suspenso para compra';
                  } else {
                    situation = '🔴 Inativo';
                  }
                }

                let aux = {
                  id: resp.data[i].id,
                  code: resp.data[i].code,
                  name: resp.data[i].name,
                  secretary: resp.data[i].tree_name,
                  expense_nature: resp.data[i].expense_nature,
                  supply_line: resp.data[i].supply_line,
                  expense_product: resp.data[i].expense_product,
                  product_value: resp.data[i].product_value,
                  description: resp.data[i].description,
                  situation: situation,
                };

                rows.push(aux);
              }
  
              setPageRows(rows);
              setBackdropOpen(false);

              for (let i = 0; i < resp.data.length; i++) {
                product_group.push(await getGroupById(resp.data[i].product_group));
                product_class.push(await getClassById(resp.data[i].class));
                product_pdm.push(await getPdmById(resp.data[i].pdm));
                product_material.push(await getMaterialById(resp.data[i].material));
                service_session.push(await getSessionById(resp.data[i].session));
                service_division.push(await getDivisionById(resp.data[i].division));
                service_group.push(await getServiceGroupById(resp.data[i].service_group));
                service_class.push(await getServiceClassById(resp.data[i].service_class));
                service_subclass.push(await getServiceSubclassById(resp.data[i].service_subclass));
                service.push(await getServiceById(resp.data[i].service));

                if (product_group[i] !== undefined || product_class[i] !== undefined) {
                  rows = [];
                  let aux = {
                    id: resp.data[i].id,
                    code: resp.data[i].code,
                    name: resp.data[i].name,
                    secretary: resp.data[i].tree_name,
                    expense_nature: resp.data[i].expense_nature,
                    group: product_group[i],
                    class: product_class[i],
                    pdm: product_pdm[i],
                    material: product_material[i],
                    session: service_session[i],
                    division: service_division[i],
                    service_group: service_group[i],
                    service_class: service_class[i],
                    service_subclass: service_subclass[i],
                    service: service[i],
                    supply_line: resp.data[i].supply_line,
                    expense_product: resp.data[i].expense_product,
                    product_value: resp.data[i].product_value,
                    description: resp.data[i].description,
                    situation: situation,
                  };
                  //rows = Object.assign([], rows);
                  rows.push(aux);
                }
              }

              setPageRows(rows);
  
            } else {
              setBackdropOpen(false);
              //alert(resp.data);
            }
          }
        }
        setBackdropOpen(false);
      } catch (error) {
        setBackdropOpen(false);
        alert(error);
      }
    }

  }

  async function handleSearch() {

    setBackdropOpen(true);

    try {
      for (let i = 0; i < rows.length; i++) {
        rows.splice(i, 1);
      }
      setPageRows(rows);
    } catch (error) {
      console.log(error);
    }

    try {

      let resp = await api.get(`/filteredproducts`, {
        params: {
          item_type: objectType,
          situation: situation,
          code: code,
          name: name,
          expense_nature: expenseNature,
          group: group,
          classification: classification,
          pdm: pdm,
          material: material,
          session: session,
          division: division,
          service_group: serviceGroup,
          service_class: serviceClass,
          service_subclass: serviceSubclass,
          service: service,
          supply_line: supplyLine,
          expense_product: expenseProduct,
          product_value: productValue,
          product_description: productDescription,
          place: profile.place,
          user_type: profile.type,
          secretary_id: profile.secretary_id,
        }
      });

      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            await setProducts([]);
            products.push(resp.data);
            await setProducts(products);

            let product_group = [];
            let product_class = [];
            let product_pdm = [];
            let product_material = [];
            let service_session = [];
            let service_division = [];
            let service_group = [];
            let service_class = [];
            let service_subclass = [];
            let service = [];

            for (let i = 0; i < resp.data.length; i++) {
              let situation = '';
              if (resp.data[i].situation === 'ativo') {
                situation = '🟢 Ativo';
              } else {
                if (resp.data[i].situation === 'suspenso') {
                  situation = '🟡 Suspenso para compra';
                } else {
                  situation = '🔴 Inativo';
                }
              }

              product_group.push(await getGroupById(resp.data[i].product_group));
              product_class.push(await getClassById(resp.data[i].class));
              product_pdm.push(await getPdmById(resp.data[i].pdm));
              product_material.push(await getMaterialById(resp.data[i].material));
              service_session.push(await getSessionById(resp.data[i].session));
              service_division.push(await getDivisionById(resp.data[i].division));
              service_group.push(await getServiceGroupById(resp.data[i].service_group));
              service_class.push(await getServiceClassById(resp.data[i].service_class));
              service_subclass.push(await getServiceSubclassById(resp.data[i].service_subclass));
              service.push(await getServiceById(resp.data[i].service));

              let aux = {
                id: resp.data[i].id,
                code: resp.data[i].code,
                name: resp.data[i].name,
                expense_nature: resp.data[i].expense_nature,
                group: product_group[i],
                class: product_class[i],
                pdm: product_pdm[i],
                material: product_material[i],
                session: service_session[i],
                division: service_division[i],
                service_group: service_group[i],
                service_class: service_class[i],
                service_subclass: service_subclass[i],
                service: service[i],
                supply_line: resp.data[i].supply_line,
                expense_product: resp.data[i].expense_product,
                product_value: resp.data[i].product_value,
                description: resp.data[i].description,
                situation: situation,
              };
              rows = Object.assign([], rows);
              rows.push(aux);
            }

            setPageRows(rows);

          }
        }
      }
     
    } catch (error) {
      alert(error);
    }

    setBackdropOpen(false);
  }

  return (
    <Card {...props} sx={{ backgroundColor: '#E5E6F0' }}>
      <CardContent sx={{ display: 'flex', height: '100%' }}>
        <Container maxWidth="lg">

          <Grid item xs={12} sx={styles.directionRow}>
            <FormControl variant="outlined" style={{ marginBottom: 15 }}>
              <InputLabel htmlFor="outlined-object-type-native-simple">
                Tipo *
              </InputLabel>
              <Select
                native
                required
                value={objectType}
                onChange={(e) => {
                  setObjectType(e.target.value);
                }}
                label="Tipo"
                inputProps={{
                  name: 'object-type',
                  id: 'outlined-object-type-native-simple',
                }}
              >
                <option value={'select'}>Selecione um tipo</option>
                <option value={'material'}>Material</option>
                <option value={'service'}>Serviço</option>
              </Select>
            </FormControl>
            
            {objectType !== '' ? (
              <Grid item xs={12} sx={{ alignItems: 'center', marginLeft: 2, width: '65%' }}>
                <Stack spacing={3}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={[
                      { situation: 'ativo', label: '🟢 Ativo' },
                      { situation: 'suspenso', label: '🟡 Suspenso para compra' },
                      { situation: 'inativo', label: '🔴 Inativo' },
                    ]}
                    value={situation}
                    onChange={(event, newValue) => {
                      setSituation(newValue);
                    }}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Situação"
                        placeholder="Selecione uma ou mais situações"
                      />
                    )}
                  />
                </Stack>
              </Grid>
            ) : null}
          </Grid>

          {objectType !== '' ? (
            <Grid item xs={12}>

              <Grid item xs={12} sx={styles.directionRow}>
                <TextField
                  fullWidth
                  label="Código do material/serviço"
                  margin="normal"
                  name="code"
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  type="text"
                  value={code}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  label="Nome do material/serviço"
                  margin="normal"
                  name="name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  type="text"
                  value={name}
                  variant="outlined"
                  sx={{ marginLeft: 2 }}
                />
              </Grid>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-secretary-native-simple">
                  Natureza da despesa *
                </InputLabel>
                <Select
                  native
                  required
                  value={expenseNature}
                  onChange={(e) => setExpenseNature(e.target.value)}
                  label="Natureza da despesa"
                  inputProps={{
                    name: 'secretary',
                    id: 'outlined-secretary-native-simple',
                  }}
                >
                  <option value="select">Selecione a natureza da despesa</option>
                  <option value="consumo">Produto de consumo</option>
                  <option value="permanete">Produto permanente</option>
                  <option value="obras">Obras</option>
                  <option value="consumopermanente">Consumo/Permanente</option>
                </Select>
              </FormControl>

              {advancedSearch ? (
                <Grid>
                  {objectType === 'material' ? (
                    <Grid item xs={12}>

                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                        <InputLabel htmlFor="outlined-group-native-simple">
                          Grupo *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={group}
                          onChange={(e) => { setGroup(e.target.value); getClassList(e.target.value); }}
                          label="Grupo"
                          inputProps={{
                            name: 'group',
                            id: 'outlined-group-native-simple',
                          }}
                        >
                          <option value="select">Selecione o grupo do item</option>
                          {groupList.map((item) =>
                            <option value={`${item.codigo}`}>{item.descricao}</option>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                        <InputLabel htmlFor="outlined-class-native-simple">
                          Classe *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={classification}
                          onChange={(e) => { setClassification(e.target.value); getPdmList(e.target.value); }}
                          label="Classe"
                          inputProps={{
                            name: 'class',
                            id: 'outlined-class-native-simple',
                          }}
                        >
                          <option value="select">Selecione o classe do item</option>
                          {classList.map((item) =>
                            <option value={`${item.codigo}`}>{item.descricao}</option>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                        <InputLabel htmlFor="outlined-pdm-native-simple">
                          Padrão descritivo do material (PDM) *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={pdm}
                          onChange={(e) => { setPdm('0' + e.target.value); getMaterialList(e.target.value); }}
                          label="Padrão descritivo do material (PDM)"
                          inputProps={{
                            name: 'pdm',
                            id: 'outlined-pdm-native-simple',
                          }}
                        >
                          <option value="select">Selecione o PDM do item</option>
                          {pdmList.map((item) =>
                            <option value={`${item.codigo}`}>{item.descricao}</option>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                        <InputLabel htmlFor="outlined-material-native-simple">
                          Material *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={material}
                          onChange={(e) => { setMaterial(e.target.value); }}
                          label="Material"
                          inputProps={{
                            name: 'material',
                            id: 'outlined-material-native-simple',
                          }}
                        >
                          <option value="select">Selecione o material do item</option>
                          {materialList.map((item) =>
                            <option value={`${item.codigo}`}>{item.descricao}</option>
                          )}
                        </Select>
                      </FormControl>

                    </Grid>
                  ) : (
                    <Grid item xs={12}>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                          <InputLabel htmlFor="outlined-session-native-simple">
                            Sessão *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={session}
                            onChange={(e) => { setSession(e.target.value); getDivisionList(e.target.value); }}
                            label="Sessão"
                            inputProps={{
                              name: 'session',
                              id: 'outlined-session-native-simple',
                            }}
                          >
                            <option value="select">Selecione a sessão do item</option>
                            {sessionList.map((item) =>
                              <option value={`${item.codigo}`}>{item.descricao}</option>
                            )}
                          </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                          <InputLabel htmlFor="outlined-division-native-simple">
                            Divisão *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={division}
                            onChange={(e) => { setDivision(e.target.value); getServiceGroupList(e.target.value); }}
                            label="Divisão"
                            inputProps={{
                              name: 'division',
                              id: 'outlined-division-native-simple',
                            }}
                          >
                            <option value="select">Selecione a divisão do item</option>
                            {divisionList.map((item) =>
                              <option value={`${item.codigo}`}>{item.descricao}</option>
                            )}
                          </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                          <InputLabel htmlFor="outlined-serviceGroup-native-simple">
                            Grupo *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={serviceGroup}
                            onChange={(e) => { setServiceGroup(e.target.value); getServiceClassList(e.target.value); }}
                            label="Grupo"
                            inputProps={{
                              name: 'serviceGroup',
                              id: 'outlined-serviceGroup-native-simple',
                            }}
                          >
                            <option value="select">Selecione a grupo do item</option>
                            {serviceGroupList.map((item) =>
                              <option value={`${item.codigo}`}>{item.descricao}</option>
                            )}
                          </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                          <InputLabel htmlFor="outlined-serviceClass-native-simple">
                            Classe *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={serviceClass}
                            onChange={(e) => { setServiceClass(e.target.value); getServiceSubclassList(e.target.value); getServiceList(null); }}
                            label="Classe"
                            inputProps={{
                              name: 'serviceClass',
                              id: 'outlined-serviceClass-native-simple',
                            }}
                          >
                            <option value="select">Selecione a classe do item</option>
                            {serviceClassList.map((item) =>
                              <option value={`${item.codigo}`}>{item.descricao}</option>
                            )}
                          </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                          <InputLabel htmlFor="outlined-serviceSubclass-native-simple">
                            Subclasse *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={serviceSubclass}
                            onChange={(e) => { setServiceSubclass(e.target.value); getServiceList(e.target.value); }}
                            label="Subclasse"
                            inputProps={{
                              name: 'serviceSubclass',
                              id: 'outlined-serviceSubclass-native-simple',
                            }}
                          >
                            <option value="select">Selecione a subclasse do item</option>
                            {serviceSubclassList.map((item) =>
                              <option value={`${item.codigo}`}>{item.descricao}</option>
                            )}
                          </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                          <InputLabel htmlFor="outlined-service-native-simple">
                            Serviço *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={service}
                            onChange={(e) => { setService(e.target.value); }}
                            label="Serviço"
                            inputProps={{
                              name: 'service',
                              id: 'outlined-service-native-simple',
                            }}
                          >
                            <option value="select">Selecione o serviço do item</option>
                            {serviceList.map((item) =>
                              <option value={`${item.codigo}`}>{item.descricao}</option>
                            )}
                          </Select>
                        </FormControl>

                    </Grid>
                  )}

                  <TextField
                    margin="dense"
                    id="supplyLine"
                    label="Linha de forneceimento"
                    type="text"
                    fullWidth
                    value={supplyLine}
                    onChange={(e) => setSupplyLine(e.target.value)}
                  />

                  <TextField
                    margin="dense"
                    id="expenseProduct"
                    label="Produto de despesa"
                    type="text"
                    fullWidth
                    value={expenseProduct}
                    onChange={(e) => setExpenseProduct(e.target.value)}
                  />

                  <TextField
                    margin="dense"
                    type="number"
                    id="productValue"
                    label="Valor do produto"
                    fullWidth
                    value={productValue}
                    onChange={(e) => setProductValue(e.target.value)}
                  />

                  <TextField
                    margin="dense"
                    id="productDescription"
                    label="Descrição do produto"
                    type="text"
                    multiline
                    fullWidth
                    value={productDescription}
                    onChange={(e) => setProductDescription(e.target.value)}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sx={styles.directionRow}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSearch}
                  startIcon={<SearchIcon />}
                  sx={styles.button}
                >
                  Buscar
                </Button>
                <FormControlLabel
                  control={<Switch
                    checked={advancedSearch}
                    onChange={() => {setAdvancedSearch(!advancedSearch);
                      setGroup(''); setClassification(''); setPdm(''); setMaterial('');
                      setSession(''); setDivision(''); setServiceGroup(''); setServiceClass('');
                      setServiceSubclass(''); setService('');
                    }}
                  />}
                  label="Busca avançada"
                  sx={{ marginLeft: 2 }}
                />
                {/*<IconButton
                  aria-label="close"
                  color="primary"
                  size="small"
                  onClick={handleMenu}
                >
                  <ImportExport fontSize="inherit" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => { handleClose(); }}><PictureAsPdf />Exportar como PDF</MenuItem>
                  <MenuItem onClick={() => { handleClose(); }}><Grid4x4 />Exportar como Planilha</MenuItem>
                  <MenuItem onClick={() => { handleClose(); }}><Article />Exportar como Documento</MenuItem>
                </Menu>*/}
              </Grid>

            </Grid>
          ) : null}

          <DataGrid
            components={{ Toolbar: GridToolbar }}
            style={{ height: 500, width: '100%' }}
            rows={page_rows}
            columns={columns}
            pageSize={7}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rowsPerPageOptions={[7]}
            disableSelectionOnClick
            onCellClick={async (row) => {
              await rowsArray.push(row);
              await setProductId(rowsArray[0].row.id);
              rowsArray.pop();
            }}
          />

          <Backdrop
            sx={{ color: '#19194b', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
            onClick={() => { setBackdropOpen(false); }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

        </Container>
      </CardContent>
    </Card>
  );
}
