import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#19194b',
    },
    secondary :{
      main: '#343434',
    }
  },
});

export default theme;