import * as React from 'react';
import api from '../../services/api';
import apiStringServer from '../../services/apiStringServer';

import { Box, Card,CardContent, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { FileUpload, PictureAsPdf, Grid4x4, Article } from '@mui/icons-material';

import Performance from '../../components/dashboards/Performance';
import PercentPie from '../../components/dashboards/PercentPie';
import LineChart from '../../components/dashboards/LineChart';
import StackedBar from '../../components/dashboards/StackedBar';
import RadarChart from '../../components/dashboards/RadarChart';
import MultitypeChart from '../../components/dashboards/MultitypeChart';
import PolarArea from '../../components/dashboards/PolarArea';

import styles from '../styles/styles';

const user = {
  id: '',
  name: '',
  email: '',
  cpf_cnpj: '',
  type: '',
  place: '',
  photo_url: '',
  phone: '',
  cep: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  secretary_id: '',
};

function Dashboard({ profile, props }) {

  React.useEffect(() => {

    user.id = profile.id;
    user.name = profile.name;
    user.email = profile.email;
    user.cpf_cnpj = profile.cpf_cnpj;
    user.type = profile.type;
    user.place = profile.place;
    user.phone = profile.phone;
    user.cep = profile.cep;
    user.street = profile.street;
    user.number = profile.number;
    user.complement = profile.complement;
    user.district = profile.district;
    user.city = profile.city;
    user.state = profile.state;
    user.secretary_id = profile.secretary_id;

    handleForm();

  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [mobileView, setMobileView] = React.useState(false);
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleForm() {

    if ( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }

  }

  return (
    <Card {...props} sx={{ backgroundColor: '#E5E6F0', width: mobileView ? '200%' : '100%' }}>
      <CardContent sx={{ overflowX: 'auto', flexGrow: 1 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={styles.directionRow}>
            <Box style={styles.directionColumn}>
              <Typography style={styles.text}>
                Bem vindo {profile.name} - {profile.type === 1 ? 'Administrador(a)' : profile.type === 2 ? 'Órgão' :
                  profile.type === 3 ? 'Secretaria de Planejamento' : profile.type === 4 ? 'Analista' : 'Servidor'}
              </Typography>
              <Box style={styles.directionRow}>
                <Typography style={styles.text}>
                  Orçamento total estipulado para o ano de 2023 da cidade é de aproximadamente:&nbsp;
                </Typography>
                <Typography style={styles.text2}>
                  {profile.place === 3 ? 'R$ 40,2 bilhões' : 
                  profile.place === 4 ? 'R$ 228,91 milhões' : 
                  profile.place === 6 ? 'R$ 1,3 bilhão' : 
                  profile.place === 7 ? 'R$ 72,4 milhões' : 
                  profile.place === 8 ? 'R$ 230,33 milhões' : 'Valor não informado'}
                </Typography>
              </Box>
            </Box>
            <IconButton
              aria-label="close"
              color="primary"
              size="small"
              onClick={handleMenu}
              sx={{ ml: 2, mt: -0.5 }}
            >
              <FileUpload fontSize="inherit" />Exportar
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { handleClose(); window.print(); }}><PictureAsPdf />Exportar como PDF</MenuItem>
              <MenuItem onClick={() => { handleClose(); }}><Grid4x4 />Exportar como Planilha</MenuItem>
              <MenuItem onClick={() => { handleClose(); }}><Article />Exportar como Documento</MenuItem>
            </Menu>
          </Box>
          
          {mobileView ? (
            <Box sx={{ width: '100%' }}>
              {/*<LineChart style={{ marginLeft: 7 }} />*/}
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
                {profile.place !== undefined ? (
                  <Performance
                    profile={profile}
                    mobileView={mobileView}
                    style={{ margin: 7 }}
                  />
                ) : null}
                {profile.place !== undefined ? (
                  <PercentPie
                    profile={profile}
                    mobileView={mobileView}
                    style={{ margin: 7 }}
                  />
                ) : null}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2, width: '100%', justifyContent: 'center' }}>
                {profile.place !== undefined ? (
                  <StackedBar
                    profile={profile}
                    mobileView={mobileView}
                    style={{ margin: 7 }}
                  />
                ) : null}
                {profile.place !== undefined ? (
                  <RadarChart
                    profile={profile}
                    mobileView={mobileView}
                    style={{ margin: 7 }}
                  />
                ) : null}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2, width: '100%', justifyContent: 'center' }}>
                {profile.place !== undefined ? (
                  <MultitypeChart
                    profile={profile}
                    mobileView={mobileView}
                    style={{ margin: 7 }}
                  />
                ) : null}
                {profile.place !== undefined ? (
                  <PolarArea
                    profile={profile}
                    mobileView={mobileView}
                    style={{ margin: 7 }}
                  />
                ) : null}
              </Box>
            </Box>
          ) : (
            <div style={{ width: '100%', marginLeft: '10%' }}>
              {/*<LineChart style={{ marginLeft: 7 }} />*/}
              <Box sx={{ display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'center' }}>
                {profile.place !== undefined ? (
                  <Performance
                    profile={profile}
                    style={{ margin: 7 }}
                  />
                ) : null}
                {profile.place !== undefined ? (
                  <PercentPie
                    profile={profile}
                    style={{ margin: 7 }}
                  />
                ) : null}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2, width: '90%', justifyContent: 'center' }}>
                {profile.place !== undefined ? (
                  <StackedBar
                    profile={profile}
                    style={{ margin: 7 }}
                  />
                ) : null}
                {profile.place !== undefined ? (
                  <RadarChart
                    profile={profile}
                    style={{ margin: 7 }}
                  />
                ) : null}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2, width: '90%', justifyContent: 'center' }}>
                {profile.place !== undefined ? (
                  <MultitypeChart
                    profile={profile}
                    style={{ margin: 7 }}
                  />
                ) : null}
                {profile.place !== undefined ? (
                  <PolarArea
                    profile={profile}
                    style={{ margin: 7 }}
                  />
                ) : null}
              </Box>
            </div>
          )}

        </Box>
      </CardContent>
    </Card>
  );
};

export default Dashboard;
