import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import styles from './styles/componentStyles';

export default function Footer() {
  return (
    <footer style={styles.footer}>
      <Typography variant="body2" style={{ color: '#E5E6F0' }} align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://kintelligence.com.br" target="_blank">
          K-Intelligence
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </footer>
  );
}
